import React, { useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { SSO_APP_ID } from '~/settings';

import { getClientProviders } from '~/api/sso-auth';
import { signIn, tokenRefresh } from '~/api/auth';

import { login, upadateTokenKey } from '~/services/auth';

import { getOrganizationStored, storeOrganizationId, getError } from '~/utils';

import { showMessage, closeMessage } from '~/store/ducks/messageBar';
import { setOrganization } from '~/store/ducks/organization';

import Carousel from '~/components/carousel';
import SlideBalloon from './components/SlideBalloon';
import CustomInput from './components/CustomInput';
import Button from '~/components/button';
import Icon from '~/components/icon';
import CustomLoading from '~/components/custom-loading';

import loginSlide1Image from '~/assets/images/login-slide-1.png';
import loginSlide2Image from '~/assets/images/login-slide-2.png';
import loginSlide3Image from '~/assets/images/login-slide-3.svg';
import appLogo from '~/assets/images/app_logo.svg';
import appLogoWhite from '~/assets/images/app_logo_white.svg';

import unionBottom from '~/assets/images/union-bottom.svg';
import unionTop from '~/assets/images/union-top.svg';

import { StyledSignIn } from './styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StyledCarouselImage } from '~/components/carousel/styles';

import { useTranslation } from 'react-i18next';

function SignIn(props) {
  const { t } = useTranslation();

  const [client, setClient] = useState('');
  const [clientErrorMessage, setClientErrorMessage] = useState('');

  const [username, setUsername] = useState('');
  const [usernameErrorMessage, setUsernameErrorMessage] = useState('');

  const [password, setPassword] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [ssoProviders, setSSOProviders] = useState([]);
  const [providersLoading, setProvidersLoading] = useState(false);
  const [legacyLoginLoading, setLegacyLoginLoading] = useState(false);

  const [currentSSOId, setCurrentSSOId] = useState('');
  const [ssoSignInLoading, setSSOSignInLoading] = useState(false);

  const [currentFormStep, setCurrentFormStep] = useState(1);

  const handleClient = event => {
    setClient(event.target.value);
    setClientErrorMessage('');
  };

  const handleUsername = event => {
    setUsername(event.target.value);
    setUsernameErrorMessage('');
  };

  const handlePassword = event => {
    setPassword(event.target.value);
    setPasswordErrorMessage('');
  };

  const handleSSO3PSignIn = idp => {
    setCurrentSSOId(idp);
    setSSOSignInLoading(true);
    window.location.href = `https://ms.auth.useflow.tech/sso3p/signin/?service=${SSO_APP_ID}&idp=${idp}&device=web`;
  };

  const handleLegacyLogin = () => {
    let hasFieldRequired = false;
    if (!client || client.trim().length === 0) {
      hasFieldRequired = true;
      setClientErrorMessage('Este campo é obrigatório');
    }

    if (!username || username.trim().length === 0) {
      hasFieldRequired = true;
      setUsernameErrorMessage('Este campo é obrigatório');
    }

    if (!password) {
      hasFieldRequired = true;
      setPasswordErrorMessage('Este campo é obrigatório');
    }

    if (hasFieldRequired) {
      return;
    }

    setLegacyLoginLoading(true);

    signIn(client, username, password)
      .then(res => {
        const organizations = res.data.account.organization_is_active_set;
        const organizationStored = getOrganizationStored(organizations);
        let activeOrganization = organizationStored || organizations[0];

        tokenRefresh({
          organization: activeOrganization.id,
          token: res.data.token
        }).then(response => {
          closeMessage();
          login(response.data.token);
          upadateTokenKey(response.data.token);
          setOrganization(activeOrganization.id);
          storeOrganizationId(activeOrganization.id);

          props.history.push(_.get(props, 'location.state.from') || '/');
        });
      })
      .catch(e => {
        getError(e, res => {
          props.showMessage('Credenciais inválidas', 'danger');
        });
      })
      .finally(() => {
        setLegacyLoginLoading(false);
      });
  };

  const handleLoadProviders = () => {
    if (!client || client.trim().length === 0) {
      setClientErrorMessage('Este campo é obrigatório');
      return;
    }

    setProvidersLoading(true);

    getClientProviders(client)
      .then(response => {
        setSSOProviders(response.data);
        if (response.data.length > 0) {
          setCurrentFormStep(2);
          return;
        }

        setCurrentFormStep(3);
      })
      .catch(() => {
        setCurrentFormStep(3);
      })
      .finally(() => {
        setProvidersLoading(false);
      });
  };

  return (
    <StyledSignIn>
      <div className="content-section">
        <div className="forms-wrapper">
          <div className="signin-form">
            {currentFormStep === 1 && (
              <div className="client-step">
                <img src={appLogo} alt="USEFLOW" />
                <CustomInput
                  label={t('Empresa')}
                  icon="home-line"
                  placeholder={t('Sua empresa')}
                  className="client-field"
                  value={client}
                  onChange={handleClient}
                  onKeyUp={event => {
                    if (event.key === 'Enter' || event.keyCode === 13) {
                      handleLoadProviders();
                    }
                  }}
                  disabled={providersLoading}
                  hasError={clientErrorMessage}
                  errorMessage={clientErrorMessage}
                />
                <Button
                  color="primary"
                  onClick={() => handleLoadProviders()}
                  disabled={providersLoading}
                  className="continue-button"
                >
                  {providersLoading ? (
                    <CustomLoading type="bubbles" color="#ffffff" />
                  ) : (
                    t('Continuar')
                  )}
                </Button>
              </div>
            )}

            {currentFormStep === 2 && (
              <div className="sso-step">
                <Button
                  className="back-button"
                  onClick={() => setCurrentFormStep(1)}
                >
                  <Icon name="back" /> {t('Voltar')}
                </Button>
                <img src={appLogo} alt="USEFLOW" />
                <h2>{t('Acesse sua conta')}</h2>
                <Button
                  color="primary"
                  onClick={() => setCurrentFormStep(3)}
                  disabled={ssoSignInLoading}
                >
                  <Icon name="useflow" />
                  <span>{t('Entrar com')} USEFLOW</span>
                </Button>

                {ssoProviders.map(ssoButton => (
                  <Button
                    color="primary"
                    key={ssoButton.id}
                    onClick={() => handleSSO3PSignIn(ssoButton.id)}
                    disabled={ssoSignInLoading}
                  >
                    {currentSSOId === ssoButton.id && ssoSignInLoading ? (
                      <CustomLoading type="bubbles" color="#ffffff" />
                    ) : (
                      `${t('Entrar com')} ${ssoButton.name}`
                    )}
                  </Button>
                ))}
              </div>
            )}

            {currentFormStep === 3 && (
              <div className="login-step">
                <Button
                  className="back-button"
                  onClick={() =>
                    setCurrentFormStep(ssoProviders.length > 0 ? 2 : 1)
                  }
                >
                  <Icon name="back" /> {t('Voltar')}
                </Button>

                <img src={appLogo} alt="USEFLOW" />
                <h2>{t('Acesse sua conta')}</h2>

                <CustomInput
                  label={t('Empresa')}
                  icon="home-line"
                  placeholder={t('Sua empresa')}
                  className="client-field"
                  value={client}
                  onChange={handleClient}
                  id="step-3-client"
                  hasError={clientErrorMessage}
                  errorMessage={clientErrorMessage}
                  disabled={legacyLoginLoading}
                  onKeyUp={event => {
                    if (event.key === 'Enter' || event.keyCode === 13) {
                      handleLegacyLogin();
                    }
                  }}
                />
                <CustomInput
                  label={t('Usuário')}
                  icon="user-line"
                  placeholder={t('Seu usuário')}
                  value={username}
                  onChange={handleUsername}
                  id="step-3-username"
                  hasError={usernameErrorMessage}
                  errorMessage={usernameErrorMessage}
                  disabled={legacyLoginLoading}
                  onKeyUp={event => {
                    if (event.key === 'Enter' || event.keyCode === 13) {
                      handleLegacyLogin();
                    }
                  }}
                />
                <CustomInput
                  label={t('Senha')}
                  icon="lock-line"
                  placeholder={t('Sua senha')}
                  value={password}
                  onChange={handlePassword}
                  type={showPassword ? 'text' : 'password'}
                  actions={
                    <button
                      className="show-password no-button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon name={showPassword ? 'not-eye' : 'eye'} />
                    </button>
                  }
                  id="step-3-password"
                  hasError={passwordErrorMessage}
                  errorMessage={passwordErrorMessage}
                  disabled={legacyLoginLoading}
                  onKeyUp={event => {
                    if (event.key === 'Enter' || event.keyCode === 13) {
                      handleLegacyLogin();
                    }
                  }}
                />
                <Button
                  className={'recovery'}
                  to={`/recovery?client=${client}`}
                >
                  {t('Esqueceu sua senha?')}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    handleLegacyLogin();
                  }}
                  disabled={legacyLoginLoading}
                >
                  {legacyLoginLoading ? (
                    <CustomLoading type="bubbles" color="#ffffff" />
                  ) : (
                    t('Continuar')
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="carousel-section">
        <img src={appLogoWhite} alt="USEFLOW" className="mobile-app-logo" />

        <img src={unionTop} alt="" className="union union-top" />
        <img src={unionBottom} alt="" className="union union-bottom" />

        <Carousel
          slideElements={[
            <StyledCarouselImage
              className="carousel-image"
              image={loginSlide1Image}
            >
              <SlideBalloon>
                Ambiente digital para todos da sua organização
              </SlideBalloon>
            </StyledCarouselImage>,
            <StyledCarouselImage
              className="carousel-image"
              image={loginSlide2Image}
            >
              <SlideBalloon>
                Colaboração e eficiência na conexão digital
              </SlideBalloon>
            </StyledCarouselImage>,
            <StyledCarouselImage
              className="carousel-image"
              image={loginSlide3Image}
            >
              <SlideBalloon>
                Seguro, simples e acessível para os colaboradores
              </SlideBalloon>
            </StyledCarouselImage>
          ]}
        />
      </div>
    </StyledSignIn>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage,
      closeMessage,
      setOrganization
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(SignIn));
