import React from 'react';
import { useTranslation } from 'react-i18next';

import LimitedTextarea from '~/components/limited-textarea';

import { StyledResearchQuestion } from './styles';
import QuestionChoice from '../QuestionChoice';

function ResearchFormQuestion({
  index,
  currentQuestion,
  setQuestion,
  disabled = false
}) {
  const { t } = useTranslation();
  const hasError = currentQuestion.error.hasError;

  const handleFieldChange = ({ target }) => {
    setQuestion(index, target.value);
  };

  const buildQuestionInput = () => {
    switch (currentQuestion.category) {
      case 'open_answer':
        return (
          <LimitedTextarea
            limit={1000}
            value={currentQuestion.value || ''}
            rows={3}
            onChange={handleFieldChange}
            placeholder={t('Insira sua resposta')}
            disabled={disabled}
            className={hasError ? 'failure' : ''}
          />
        );
      case 'point_scale':
        return (
          <QuestionChoice
            currentQuestion={currentQuestion}
            hasError={hasError}
            handleFieldChange={handleFieldChange}
            disabled={disabled}
          />
        );
      case 'single_choice':
        return (
          <QuestionChoice
            currentQuestion={currentQuestion}
            hasError={hasError}
            handleFieldChange={handleFieldChange}
            disabled={disabled}
          />
        );
      case 'multiple_choice':
        return (
          <QuestionChoice
            currentQuestion={currentQuestion}
            hasError={hasError}
            handleFieldChange={handleFieldChange}
            disabled={disabled}
          />
        );
      default:
        return <p>Ocorreu um erro ao carregar o questão</p>;
    }
  };

  return (
    <StyledResearchQuestion>
      <div className="field-label">
        <div className="question-number">{index + 1}</div>
        <div className="question-title">
          <p className="question">
            {currentQuestion.title}
            <span>{currentQuestion.is_required ? '*' : ''}</span>
          </p>
        </div>
      </div>
      {buildQuestionInput()}
      {hasError && (
        <span className="error">
          <i>{currentQuestion.error.message}</i>
        </span>
      )}
    </StyledResearchQuestion>
  );
}

export default ResearchFormQuestion;
