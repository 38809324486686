import React from 'react';
import './style.scss';

const CheckMultipleList = ({
  name,
  options,
  onChange,
  values,
  disabled = false
}) => (
  <div className="check-list">
    {options.map(opt => (
      <label key={opt.value} className="check-item">
        <input
          name={name}
          type="checkbox"
          value={opt.value}
          onChange={e => onChange(e)}
          checked={values.includes(opt.value)}
          disabled={disabled}
        />
        <span>{opt.name}</span>
      </label>
    ))}
  </div>
);

export default CheckMultipleList;
