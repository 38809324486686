import React from 'react';

import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Card from '~/components/card';
import PostHeader from '~/components/post-header';

import { research_corver as ResearchIcon } from '~/components/svg-icons/svg-icons';

import moment from 'moment';

import './styles.scss';
import Button from '~/components/button';
import Icon from '~/components/icon';
import PostImage from '~/components/post-image';

const Research = props => {
  const { t, i18n } = useTranslation();
  const userLanguage = i18n.language;

  return (
    <Card>
      <PostHeader
        info={props.anonymous ? t('Pesquisa anônima') : t('Pesquisa')}
        icon_type={props.anonymous ? 'anonymous' : 'research'}
        channel="research"
        organization={props.original_data.organization}
        expire={props.finish_at}
      />
      <div className="card-body">
        {props.cover ? (
          <PostImage url={props.cover} />
        ) : (
          <div className="cover-item-img">
            <ResearchIcon width={350} height={147.77} />
          </div>
        )}
        <h3 className="research-title">{props.title}</h3>
        <p className="research-description text-center">{props.description}</p>
        <div className="text-center">
          <Button
            onClick={() => {
              props.history.push({
                pathname: `/survey/${props.id}/form`,
                state: {
                  researchId: props.id
                }
              });
            }}
            color={'primary'}
          >
            <Icon name={'publishing'} marginRight />
            <span>{t('Responder')}</span>
          </Button>
        </div>
      </div>

      <div className="card-footer">
        <small>
          <i>{t('Postado em')}</i>
          <i> </i>
          <i>
            {moment
              .parseZone(props.start_at)
              .locale(userLanguage)
              .format('LLL')}
          </i>
        </small>
      </div>
    </Card>
  );
};

export default withRouter(Research);
