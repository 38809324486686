import styled from 'styled-components';

export const StyledFeedbackForm = styled.div.attrs(() => ({
  className: 'research-form-page'
}))`
  width: 100%;
  display: flex;
  justify-content: center;

  .header-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side {
      .button {
        background: var(--white-color);
        padding: 0px 8px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .right-side {
      display: flex;
      justify-content: flex-end;

      .button {
        margin: 0px 4px;
        min-width: 76px;

        .custom-loading {
          margin: 0px;
        }
      }

      .button:not(.outline) {
        border: 1px solid var(--white-color);
      }

      .button.outline {
        background: var(--white-color);
      }

      .suspended-menu {
        display: none;

        & > button {
          color: var(--primary-color);
          min-width: 42px;
        }
      }
    }
  }

  .page-content {
    width: 100%;
    max-width: 800px;
    margin-top: 76px;
    padding: 64px 0px;

    .research-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .finish-date {
        margin-bottom: 12px;
        color: var(--secondary-text-color);
      }

      .collaborator-card {
        margin-bottom: 16px;

        .research-historic {
          display: flex;
          justify-content: flex-end;

          .button {
            display: flex;
            align-items: center;
          }
        }

        .show-performance-wrapper {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          a {
            color: var(--white-color);
            min-width: max-content;
            line-height: 1;
            padding: 10px 20px;
            border: 1px solid var(--white-color);
            text-decoration: none;
            border-radius: 4px;

            &:hover {
              background: none;
            }
          }
        }
      }
    }

    .custom-form-header {
      padding: 40px;
      display: flex;
      align-items: center;

      p {
        margin-top: 10px;
        font-size: 14px;
        color: var(--secondary-text-color);
        white-space: pre-line;
      }

      .text-info {
        width: 100%;

        .title {
          text-align: center;
        }

        .description {
          text-align: justify;
          margin: 20px auto;
        }
      }
    }

    .research-form {
      padding: 0px;

      .research-cover {
        display: flex;
        justify-content: center;
        img {
          position: relative;
          display: flex;
          min-height: 108px;
          margin-top: 24px;
          width: 640px;
        }
      }

      .pdi-question {
        width: 100%;
        padding: 16px 40px;

        .field-label {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          .question-number {
            width: 30px;
            min-width: 30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            line-height: 30px;
            background-color: var(--background-color);
            margin-right: 8px;
            font-size: 16px;
          }

          .question {
            font-size: 16px;
          }
        }

        .table-header {
          padding: 16px 0px;
          padding-left: 8px;

          .table-actions {
            button {
              padding: 6px 8px;
            }
          }
        }

        table {
          border-left: 1px solid var(--border-color);
          border-right: 1px solid var(--border-color);

          tr {
            th:first-child {
              min-width: 140px;
            }
          }
        }
      }
    }

    .action-button {
      display: flex;
      justify-content: space-between;
      margin: 15px auto;
    }

    .table-actions {
      button {
        border: none;
      }
    }

    .suspended-menu {
      width: 100%;
      display: flex;
      justify-content: center;

      button {
        border: none;
      }

      .suspended-content {
        min-width: 140px;
        right: 46px;
        bottom: -8px;

        button {
          color: var(--font-color) !important;
        }
      }
    }
  }

  .custom-loading {
    margin: 32px 0px;
  }

  .go-to-top {
    font-size: 18px;
    background: var(--text-color);
    padding: 6px 18px;

    i {
      margin: 0px;
    }
  }

  .go-to-top {
    font-size: 18px;
    background: var(--text-color);
    padding: 6px 18px;

    i {
      margin: 0px;
    }
  }

  .more-info {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .finish-at {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0;
    padding: 0;
    font-size: 16px;
  }

  .cover-undraw-img {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  @media (max-width: 1366px) {
    .go-to-top {
      position: absolute;
      bottom: 54px;
      right: 24px;
    }
  }

  @media (max-width: 720px) {
    .page-content {
      .performance-header {
        margin-bottom: 32px;

        .collaborator-card {
          .show-performance-wrapper {
            justify-content: center;
            align-items: center;

            button {
              margin-top: 8px;
            }
          }
        }
      }
    }

    .header-actions {
      .right-side {
        & > button {
          display: none;
        }

        .suspended-menu {
          display: block;
        }
      }
    }

    .research-form {
      & > div {
        padding: 16px !important;
      }

      .custom-form-header {
        flex-direction: column;
        align-items: flex-start;

        img {
          margin-bottom: 32px;
        }
      }
    }

    .page-content {
      padding: 16px;
    }
  }
`;
