import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckList } from '~/components/form';

import { StyledQuestionChoice } from './styles';
import CheckMultipleList from '~/components/form/check-multiple-list';

const QuestionChoice = ({
  currentQuestion,
  hasError,
  handleFieldChange,
  disabled
}) => {
  const { t } = useTranslation();

  // Options
  const getNPSOptions = () => {
    const NPSOptions = [];
    const nps = currentQuestion.nps;

    for (let i = 1; i <= nps.scale; i += 1) {
      let description = i;

      if (currentQuestion.nps.nps_has_labels) {
        if (i === 1) {
          description = `${i} - ${nps.first_label || t('Discordo')}`;
        }

        if (i === nps.scale) {
          description = `${i} - ${nps.last_label || t('Concordo')}`;
        }
      }

      NPSOptions.push({
        name: description,
        value: i
      });
    }
    return NPSOptions;
  };

  const getMappedOptions = () => {
    const options = currentQuestion.options;
    return options.map(option => {
      return {
        name: option.title,
        value: option.pk
      };
    });
  };

  // Values
  const getValue = () => {
    return currentQuestion.value;
  };

  const getMultipleChoiceValue = () => {
    return currentQuestion.value ? currentQuestion.value.split(';') : [];
  };

  // Components
  const NPSScale = (options, value) => {
    return (
      <CheckList
        name={`question_${currentQuestion.category}_${currentQuestion.id}`}
        type="radio"
        value={parseInt(value)}
        options={options}
        onChange={handleFieldChange}
        disabled={disabled}
      />
    );
  };

  const SingleChoice = (options, value) => {
    return (
      <CheckList
        name={`question_${currentQuestion.category}_${currentQuestion.id}`}
        value={value}
        options={options}
        onChange={handleFieldChange}
        disabled={disabled}
      />
    );
  };

  const MultipleChoice = (options, value) => {
    return (
      <CheckMultipleList
        name={`question_${currentQuestion.category}_${currentQuestion.id}`}
        values={value}
        options={options}
        onChange={onChangeFieldChoice}
        disabled={disabled}
      />
    );
  };

  // Utils
  const getField = {
    point_scale: {
      options: getNPSOptions,
      component: NPSScale,
      value: getValue
    },
    single_choice: {
      options: getMappedOptions,
      component: SingleChoice,
      value: getValue
    },
    multiple_choice: {
      options: getMappedOptions,
      component: MultipleChoice,
      value: getMultipleChoiceValue
    }
  };

  const onChangeFieldChoice = event => {
    let value = getMultipleChoiceValue();
    const index = value.indexOf(event.target.value);

    if (index !== -1) {
      value.splice(index, 1);
    } else {
      value.push(event.target.value);
    }

    event.target.value = value.join(';');
    handleFieldChange(event);
  };

  const buildChoiceField = () => {
    const field = getField[currentQuestion.category];
    const options = field.options();
    const value = field.value();
    return field.component(options, value);
  };

  return (
    <StyledQuestionChoice>
      <div className={currentQuestion.category}>
        <div className={`option-wrapper ${hasError ? 'failure' : ''}`}>
          {buildChoiceField()}
        </div>
      </div>
    </StyledQuestionChoice>
  );
};

export default QuestionChoice;
