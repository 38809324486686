import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getPages } from '~/api/organizations';

import { showMessage } from '~/store/ducks/messageBar';

import Card from '~/components/card';

import './styles.scss';

function Page({ match, organization: { activeOrganizationId }, showMessage }) {
  const [selectedPage, setSelectedPage] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadPage = () => {
    getPages(activeOrganizationId)
      .then(response => {
        const [currentPage] = response.data.filter(
          page => page.id === parseInt(match.params.id)
        );
        setSelectedPage(currentPage);
      })
      .catch(() => {
        showMessage('Ocorreu um erro ao carregar a página', 'danger');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadPage();
  }, [match.params.id]);

  return (
    <div className="page-screen">
      <Card>
        {!loading && selectedPage && (
          <>
            <div className="page-screen-header">
              <h1>{selectedPage.title}</h1>
            </div>
            <div className="page-screen-body">
              <div dangerouslySetInnerHTML={{ __html: selectedPage.content }} />
            </div>
          </>
        )}

        {loading && (
          <div className="page-screen-header">
            <h2 className="text-center">Carregando...</h2>
          </div>
        )}

        {!loading && !selectedPage && (
          <div className="page-screen-header">
            <h2 className="text-center">Página não encontrada</h2>
          </div>
        )}
      </Card>
    </div>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showMessage
    },
    dispatch
  );

const mapStateToProps = ({ account, organization }) => ({
  account,
  organization
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
