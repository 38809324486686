import styled from 'styled-components';

export const StyledQuestionChoice = styled.div.attrs(() => ({
  className: 'research-question-choice'
}))`
  .option-wrapper {
    cursor: pointer;
    background: #f6f6f6;
    padding: 10px;
    margin: 5px 0;
    border-radius: 4px;

    .check-wrapper {
      padding: 5px !important;
      margin: 0;
    }
    .check-list {
      display: flex;
      flex-direction: column;
      .check-item {
        display: flex;
        padding: 5px;

        input {
          margin-right: 10px;
        }

        input,
        label {
          vertical-align: middle;
        }
      }
    }
  }

  .option-wrapper.failure {
    border: 1px solid var(--failure-color);
  }
`;
